import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './views/home/home.component';
import { ListaSolicitudesLiderFormComponent } from './views/lista-solicitudes/lista-solicitudes-lider-form/lista-solicitudes-lider-form.component';
import { ListaSolicitudesRhFormComponent } from './views/lista-solicitudes/lista-solicitudes-rh-form/lista-solicitudes-rh-form.component';
import { ListaVacacionesDashboardJefeComponent } from './views/lista-vacaciones-dashboard-jefe/lista-vacaciones-dashboard-jefe.component';
import { ListaVacacionesDashboardRHComponent } from './views/lista-vacaciones-dashboard-rh/lista-vacaciones-dashboard-rh.component';
import { VacacionesComponent } from './views/vacaciones/vacaciones/vacaciones.component';
import { VacacionesRhComponent } from './views/vacaciones-rh/vacaciones-rh/vacaciones-rh.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'vacaciones', component: VacacionesComponent },
  { path: 'vacaciones-rh', component: VacacionesRhComponent },
  { path: 'solicitudes-lider', component: ListaSolicitudesLiderFormComponent },
  { path: 'solicitudes-rh', component: ListaSolicitudesRhFormComponent },
  { path: 'dasboard-lider', component: ListaVacacionesDashboardJefeComponent },
  { path: 'dasboard-rh', component: ListaVacacionesDashboardRHComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
