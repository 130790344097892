import { version } from '../../package.json';

const hostMs = "http://cuat.axkan.axks.ddns.net/ms";
const domPortal = "uat.axkan.axks.ddns.net";
const hostGui = "http://cuat.axkan.axks.ddns.net/gui";

export const environment = {
  production: false,
  dev: false,
  uat: true,
  version: version,

  appId: "vacaciones-gui",
  empresas: [
    { id: 1, domain: domPortal },
    { id: 2, domain: domPortal },
    { id: 3, domain: domPortal }
  ],

  sprofile: hostMs + "/autentica/profile",
  msVacaciones: hostMs + "/rh/vacaciones",
  msListaSolicitudLider: hostMs + "/rh/vacaciones/lista-solicitudes",
  msAutorizaLider: hostMs + "/rh/vacaciones/autorizar-vacaciones-jefe",
  msListaSolicitudRh: hostMs + "/rh/vacaciones/lista-solicitudes-RH",
  msAutorizaRh: hostMs + "/rh/vacaciones/autoriza-vacaciones-RH",
  msDashboardjefe: hostMs + '/rh/vacaciones/lista-vacaciones-dashboard-jefe',
  msDashboardRH: hostMs + '/rh/vacaciones/lista-vacaciones-dashboard-RH',
  msConfVacaciones: hostMs + '/rh/configuracion-vacaciones',
  
  urlVacacionLider: hostGui + '/gvacac/solicitudes-lider',
  urlVacacionRH: hostGui + '/gvacac/solicitudes-rh',

  msEmpleado: hostMs + '/rh/empleado/ps',
  theme: {
    default: 'https://static.uat-gui.axkans.org/portal/themes/blue-gray/styles/theme.css'
  }
};
