<ng-container *ngIf="loadContent">
    <div class="p-grid frm-part">

        <div class="p-sm-12 p-md-12 p-lg-3 p-xl-3 p-col-12" *ngIf="allowFormFields('infoDias')">
            <p-table>
                <ng-template pTemplate="header">
                    <tr>
                        <th class="th-style">Días disponibles: </th>
                        <td class="td-style">{{confVac.disponibles}}</td>
                    </tr>
                    <tr>
                        <th class="th-style">Días utilizados: </th>
                        <td class="td-style">{{confVac.utilizado}}</td>
                    </tr>
                </ng-template>
            </p-table>

            <!-- <div class="p-grid">
                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="padding-left: 2px;">
                    <span class="th-style">Días disponibles:</span>
                    <span class="td-style">{{confVac.disponibles}}</span>
                </div>
                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="padding-left: 2px;">
                    <span class="th-style">Días utilizados:</span>
                    <span class="td-style">{{confVac.utilizado}}</span>
                </div>
            </div> -->
        </div>

        <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12  p-sm-offset-0 p-md-offset-0 p-lg-offset-1 p-xl-offset-1 p-col-offset-0"
            *ngIf="allowFormFields('fechaInicioSearch')">
            <br>
            <span class="p-float-label">
                <p-calendar id="fl-fecin" [(ngModel)]="item.fechaInicio" dateFormat="yy/mm/dd" [yearNavigator]="true"
                    [showIcon]="true" inputId="icono" [yearRange]="yearRange" [monthNavigator]="true" dataType="string">
                </p-calendar>
                <label for="fl-fecin">Fecha de inicio</label>
            </span>
        </div>

        <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('fechaInicioRegister')">
            <br>
            <span class="p-float-label">
                <p-calendar id="fl-fecin" [(ngModel)]="item.fechaInicio" dateFormat="yy/mm/dd" [yearNavigator]="true"
                    [showIcon]="true" inputId="icono" [yearRange]="yearRange" [monthNavigator]="true" dataType="string"
                    [maxDate]="fecExpiracion">
                </p-calendar>
                <label for="fl-fecin">Fecha de inicio</label>
            </span>
        </div>

        <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('fechaFinRegister')">
            <br>
            <span class="p-float-label">
                <p-calendar id="fl-fecfn" [(ngModel)]="item.fechaFin" dateFormat="yy/mm/dd" [yearNavigator]="true"
                    [showIcon]="true" inputId="icon" [yearRange]="yearRange" [monthNavigator]="true" dataType="string"
                    [maxDate]="fecExpiracion">
                </p-calendar>
                <label for="fl-fecfn">Fecha final</label>
            </span>
        </div>

        <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('fechaFinSearch')">
            <br>
            <span class="p-float-label">
                <p-calendar id="fl-fecfn" [(ngModel)]="item.fechaFin" dateFormat="yy/mm/dd" [yearNavigator]="true"
                    [showIcon]="true" inputId="icon" [yearRange]="yearRange" [monthNavigator]="true" dataType="string">
                </p-calendar>
                <label for="fl-fecfn">Fecha final</label>
            </span>
        </div>

    </div>
</ng-container>