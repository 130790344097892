import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BaseMainComponent, ColumnDefinition, ComponentItem, Message, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Vacaciones } from 'src/app/api/vacaciones/vacaciones';
import { VacacionesReg } from 'src/app/api/vacaciones/vacaciones-reg';
import { VacacionesPsService } from 'src/app/procs/vacaciones/vacaciones-ps.service';


@Component({
  selector: 'axks-vacaciones',
  templateUrl: './vacaciones.component.html',
  styleUrls: ['./vacaciones.component.scss']
})
export class VacacionesComponent extends BaseMainComponent<VacacionesPsService> implements OnInit {

  profile = this.getProfile() as ProfileC;

  constructor(
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected screenModeService: OperationScreenModeService,
    protected sessionProvier: SessionClientProvider,
    private psService: VacacionesPsService
  ) {
    super(deviceService, messageService, screenModeService, sessionProvier);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.cargarTabla();
  }

  initEmptyItem(): ComponentItem {
    let empty = {
      item: {
        idEmpleado: this.profile.idEmpleado
      } as Vacaciones,

      minimumItem: {
        idEmpleado: this.profile.idEmpleado
      } as Vacaciones,

      registerItem: {
        idEmpleado: this.profile.idEmpleado
      } as VacacionesReg

    } as ComponentItem;
    return empty;
  }

  getService(): VacacionesPsService {
    return this.psService;
  }

  getColListDefinition(): ColumnDefinition[] {
    let colsDef = [
      { header: 'Num. Solicitud', field: 'idVacaciones', inAll: true },
      { header: 'Fecha Inicio', field: 'fechaInicio', inAll: true, formatFunction: this.formatoFecha },
      { header: 'Fecha Fin', field: 'fechaFin', inAll: true, formatFunction: this.formatoFecha },
      { header: 'Período', field: 'periodo', inDetail: true, inResultList: true },
      { header: 'Autorización del lider', field: 'autorizaJefe', inAll: true, formatFunction: this.formatBoolean },
      { header: 'Fecha de autorización (Lider)', field: 'fechaAutorizaJefe', inDetail: true, formatFunction: this.formatoFecha },
      { header: 'Autorización de RR.HH.', field: 'autorizaRH', inAll: true, formatFunction: this.formatBoolean },
      { header: 'Fecha de autorización (RR.HH.)', field: 'fechaAutorizaRH', inDetail: true, formatFunction: this.formatoFecha }

    ] as ColumnDefinition[];
    return colsDef;
  }

  getIdOfSelectedItem(): any {
    return this.item.minimumItem.idVacaciones || this.item.item.idVacaciones;
  }

  getComponentTitle(): string {
    return 'Mis vacaciones';
  }

  formatoFecha(fecha: any) {
    let pipe = new DatePipe('es-MX');
    return pipe.transform(fecha, "dd 'de' MMMM 'de' yyyy");
  }

  formatBoolean(value: any): string {
    if (value !== undefined && value !== "" && value !== null) {
      return (value == true ? 'Autorizado' : 'Rechazado');
    }
    else {
      return 'Pendiente...';
    }
  }

  componentsFields() {
    super.componentsFields();
    this.formSearch = ['infoDias', 'fechaInicioSearch', 'fechaFinSearch'];
    this.formFields = ['fechaInicioRegister', 'fechaFinRegister'];
  }

  cargarTabla() {
    this.getService().find(this.item.item).subscribe(
      (data) => {
        this.resultList = data;
      },
      (error) => {
        try {
          let mess = error.error as Message;
          this.processMessage(mess);
        } catch (e) {
          console.debug(e);
        }
      }
    );
  }
}
