import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { AutorizaPermisoVac, AutorizaVacaciones } from 'src/app/api/autoriza-vacaciones/autoriza-vacaciones';
import { ListaSolicitudes } from 'src/app/api/lista-solicitudes/lista-solicitudes';
import { AutorizaVacacionesRestcService } from 'src/app/client/autoriza-vacaciones/autoriza-vacaciones-restc.service';
import { ListaSolicitudesRestcService } from 'src/app/client/lista-solicitudes/lista-solicitudes-restc.service';

@Component({
  selector: 'axks-lista-solicitudes-rh-form',
  templateUrl: './lista-solicitudes-rh-form.component.html',
  styleUrls: ['./lista-solicitudes-rh-form.component.scss']
})
export class ListaSolicitudesRhFormComponent extends BaseView implements OnInit {
  [x: string]: any;

  @Input() item: ListaSolicitudes;

  listaSol: ListaSolicitudes[] = [];
  autorizaVacaciones: AutorizaVacaciones = {}
  autorizaVAcacion: AutorizaVacaciones[] = [];

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private listaRestc: ListaSolicitudesRestcService,
    private autorizaRhRestc: AutorizaVacacionesRestcService) {

    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {
    if (!this.item) {
      this.item = {};
    }
    this.obtenerListaRh();
  }

  obtenerListaRh() {
    let profile = this.getProfile() as ProfileC;;
    this.item.idEmpleado = profile.idEmpleado;
    this.showProgress = true;

    this.listaRestc.listaRh().subscribe(
      (data) => {
        this.listaSol = data as ListaSolicitudes[];
        this.showProgress = false;
        if (this.listaSol.length === 0) {
          this.messageService.add({ severity: 'info', summary: 'Sin solicitudes', detail: 'No hay ninguna solicitud de vacaciones por autorizar.' });
        }
      },
      (error) => {
        this.showProgress = false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No se pudo recuperar las solicitudes.' });
      }
    );
  }

  autorizar(valor: any) {
    let profile = this.getProfile() as ProfileC;
    this.item.idEmpleado = profile.idEmpleado;

    let aprovRh = {
      autorizacion: true,
      idEmpleadoRH: this.item.idEmpleado,
      idVacaciones: valor.idVacaciones
    };

    this.showProgress = true;

    this.autorizaRhRestc.autorizaVacacionesRH(aprovRh).subscribe(
      (data) => {
        this.autorizaVAcacion = data as AutorizaPermisoVac[];
        this.showProgress = false;
        this.messageService.add({ severity: 'success', summary: 'Solicitud aceptada', detail: 'La solicitud se autorizó correctamente.' });
        setTimeout(function () { location.reload(); }, 2000);
      },
      (error) => {
        this.showProgress = false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No se pudo envíar la solicitud.' });
      }
    );
  }

  rechazar(valor: any) {
    let profile = this.getProfile() as ProfileC;
    this.item.idEmpleado = profile.idEmpleado;

    let aprovRh = {
      autorizacion: false,
      idEmpleadoRH: this.item.idEmpleado,
      idVacaciones: valor.idVacaciones
    };

    this.showProgress = true;

    this.autorizaRhRestc.autorizaVacacionesRH(aprovRh).subscribe(
      (data) => {
        this.autorizaVAcacion = data as AutorizaPermisoVac[];
        this.showProgress = false;
        this.messageService.add({ severity: 'success', summary: 'Solicitud rechazada', detail: 'La solicitud se rechazo correctamente.' });
        setTimeout(function () { location.reload(); }, 2000);
      },
      (error) => {
        this.showProgress = false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No se pudo envíar la solicitud.' });
      }
    );
  }

}
