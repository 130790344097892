import { formatDate, registerLocaleData } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Vacaciones } from 'src/app/api/vacaciones/vacaciones';

import localeEsMx from '@angular/common/locales/es-MX';/*localizacion para dar formato*/
import { MessageService } from 'primeng/api';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { VacacionesDetalleRestcService } from 'src/app/client/vacaciones/vacaciones-detalle-restc.service';
import { ConfVacaciones } from 'src/app/api/vacaciones/conf-vacaciones';
registerLocaleData(localeEsMx, 'es-MX');

@Component({
  selector: 'axks-vacaciones-form',
  templateUrl: './vacaciones-form.component.html',
  styleUrls: ['./vacaciones-form.component.scss']
})
export class VacacionesFormComponent extends BaseView implements OnInit {

  @Input() item: Vacaciones;

  yearRange: string;
  confVac: ConfVacaciones;
  fecExpiracion: Date;

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private restc: VacacionesDetalleRestcService,) {
    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {
    this.getConfVacacionesEmpleado();

    if (!this.item) {
      this.item = {};
    }

    if (!this.confVac) {
      this.confVac = {};
    }

    this.initDates();
    this.obtenerIdEmp();

    if (this.item.fechaInicio) {
      this.item.fechaInicio = this.formatoFecha(this.item.fechaInicio)
    }

    if (this.item.fechaFin) {
      this.item.fechaFin = this.formatoFecha(this.item.fechaFin)
    }
  }

  getConfVacacionesEmpleado() {
    let perfil = this.getProfile() as ProfileC;

    let idEmpleado = perfil.idEmpleado;

    this.restc.findById(idEmpleado).subscribe(
      (data) => {
        this.confVac = data as ConfVacaciones;
        if(this.confVac.fecExpiracion) this.fecExpiracion = new Date(this.confVac.fecExpiracion);
      },
      (error) => {
        this.errorMessage("Error", "No se pudo obtener los días de vacaciones.");
      }
    );
  }

  initDates() {
    let now = new Date();
    let year = now.getFullYear();

    this.yearRange = (year - 60) + ':' + (year + 60);
  }


  obtenerIdEmp() {
    let profile = this.getProfile() as ProfileC;
    this.item.idEmpleado = profile.idEmpleado;
  }

  formatoFecha(value: string) {
    let d = Date.parse(value);

    return formatDate(d, 'yyyy/MM/dd', 'es-MX');
  }

}
