import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ListaSolicitudes } from 'src/app/api/lista-solicitudes/lista-solicitudes';

@Injectable({
  providedIn: 'root'
})
export class ListaSolicitudesRestcService {

  constructor(private httpClient: HttpClient) { }

  listaLider(idLider: number): Observable<any>{
    let path = environment.msListaSolicitudLider + "/" + idLider;
  
    const headers = new HttpHeaders()
        .set('Access-Control-Allow-Origin', "*")
        .set('Access-Control-Allow-Headers', "*");
        
    return this.httpClient.get(path, {headers});
  }

  listaRh(): Observable<any>{
    let path = environment.msListaSolicitudRh;
  
    const headers = new HttpHeaders()
        .set('Access-Control-Allow-Origin', "*")
        .set('Access-Control-Allow-Headers', "*");
        
    return this.httpClient.get(path, {headers});
  }


}
