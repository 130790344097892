import { Component, OnInit } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Dashboard } from 'src/app/api/dashboard/dashboard';
import { DashboardJefeRestcService } from 'src/app/client/dashboard-jefe/dashboard-feje-restc.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'axks-lista-vacaciones-dashboard-jefe',
  templateUrl: './lista-vacaciones-dashboard-jefe.component.html',
  styleUrls: ['./lista-vacaciones-dashboard-jefe.component.scss']
})
export class ListaVacacionesDashboardJefeComponent extends BaseView implements OnInit {

  dashboard: Dashboard[] = [];
  showContent: boolean;
  myLoadContent: boolean;
  mensajeSol: string;

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private dashJefeResct: DashboardJefeRestcService) {

    super(screenModeService, deviceService, messageService, sessionProvier);
    this.myLoadContent = false;
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.obtenerSolicitudesLider();
    }, 1500);
  }

  obtenerSolicitudesLider() {
    let profile = this.getProfile() as ProfileC;;

    this.dashJefeResct.listaLider(profile.idEmpleado).subscribe(
      (data) => {
        this.dashboard = data as Dashboard[];
        this.myLoadContent = true;
        this.initContent();
      },
      (error) => {
        this.myLoadContent = true;
        this.mensajeSol = "Error. No se pudo consultar las solicitudes de vacaciones."
      }
    );
  }

  initContent() {
    if (this.dashboard.length > 0) {
      this.showContent = true;
    }
    else {
      this.showContent = false;
      this.mensajeSol = "No hay solicitudes de vacaciones pendientes por revisar."
    }
  }

  selSolicitud(event) {
    window.parent.postMessage({ for: "redirect", url: environment.urlVacacionLider }, "*");
  }

}
