<div class="p-grid frm-part">

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('empleados')">
        <br>
        <span class="p-float-label">
            <p-dropdown inputId="fl-emp" [(ngModel)]="item.idEmpleado" [autoDisplayFirst]="false"
                [options]="catEmpleados" optionValue="id" optionLabel="nombreCompleto" [filter]="true"
                filterBy="nombreCompleto">
            </p-dropdown>
            <label for="fl-emp">Empleado</label>
        </span>
    </div>

    <!-- <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('fechaInicio')">
        <br>
        <span class="p-float-label">
            <p-calendar id="fl-fecin" [(ngModel)]="item.fechaInicio" dateFormat="yy/mm/dd" [yearNavigator]="true"
                [showIcon]="true" inputId="icono" [yearRange]="yearRange" [monthNavigator]="true" dataType="string">
            </p-calendar>
            <label for="fl-fecin">Fecha de Inicio</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('fechaFin')">
        <br>
        <span class="p-float-label">
            <p-calendar id="fl-fecin" [(ngModel)]="item.fechaInicio" dateFormat="yy/mm/dd" [yearNavigator]="true"
                [showIcon]="true" inputId="icono" [yearRange]="yearRange" [monthNavigator]="true" dataType="string">
            </p-calendar>
            <label for="fl-fecin">Fecha de Inicio</label>
        </span>
    </div> -->

</div>