import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { VacacionesRestcService } from 'src/app/client/vacaciones/vacaciones-restc.service';

@Injectable({
  providedIn: 'root'
})
export class VacacionesPsService extends BaseProcess<VacacionesRestcService>{

  constructor(httpClient: HttpClient, crest: VacacionesRestcService) {
    super(httpClient, crest);
  }
}
