import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AutorizaVacaciones } from 'src/app/api/autoriza-vacaciones/autoriza-vacaciones';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AutorizaVacacionesRestcService {

  constructor(private httpClient: HttpClient) { }

  autorizaVacacionesLider(aprovLider: any): Observable<any>{
    let path = environment.msAutorizaLider;
    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    return this.httpClient.post(path, aprovLider, { headers });
  }

  autorizaVacacionesRH( aprovRH: any): Observable<any>{
    let path = environment.msAutorizaRh ;
    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    return this.httpClient.post(path,  aprovRH, { headers });
  }
/* 
  autorizaPermiso(autorizacion:true, idEmpleadoRH: number, idJefe: Number, idPermiso: number): Observable<any>{
    let path = environment.msAutorizaPermiso;
    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    return this.httpClient.post(path, { headers });
  } */


}
