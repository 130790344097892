import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { EmpleadoNombre } from 'src/app/api/usuario/empleado';
import { Vacaciones } from 'src/app/api/vacaciones/vacaciones';

@Component({
  selector: 'axks-vacaciones-rh-form',
  templateUrl: './vacaciones-rh-form.component.html',
  styleUrls: ['./vacaciones-rh-form.component.scss']
})
export class VacacionesRhFormComponent extends BaseView implements OnInit {

  @Input() item: Vacaciones;
  @Input() catEmpleados: EmpleadoNombre[];

  yearRange: string;

  constructor(
    protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProv: SessionClientProvider
  ) {
    super(screenModeService, deviceService, messageService, sessionProv);
  }

  ngOnInit(): void {
    if(!this.item) {
      this.item = {};
    }

    this.initDates();
  }

  initDates() {
    let now = new Date();
    let year = now.getFullYear();

    this.yearRange = (year - 60) + ':' + (year + 60);
  }

}
