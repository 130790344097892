import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';


import { AppComponent } from './app.component';

import { RestClientModule } from '@axks/net';
import { HttpClientModule } from '@angular/common/http';


import { ActionsBarModule, BaseMessages, CommonsModule, DetailTabModule, OperationsBarModule, ResultListModule, SessionClientProvider } from '@axks/components';

import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { MessageService } from 'primeng/api';
import { VacacionesComponent } from './views/vacaciones/vacaciones/vacaciones.component';

import { HomeComponent } from './views/home/home.component';
import { ListaSolicitudesLiderFormComponent } from './views/lista-solicitudes/lista-solicitudes-lider-form/lista-solicitudes-lider-form.component';
import { ListaSolicitudesRhFormComponent } from './views/lista-solicitudes/lista-solicitudes-rh-form/lista-solicitudes-rh-form.component';

import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { ListaVacacionesDashboardJefeComponent } from './views/lista-vacaciones-dashboard-jefe/lista-vacaciones-dashboard-jefe.component';
import { CardModule } from 'primeng/card';
import { ListaVacacionesDashboardRHComponent } from './views/lista-vacaciones-dashboard-rh/lista-vacaciones-dashboard-rh.component';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { environment } from 'src/environments/environment';
import { VacacionesFormComponent } from './views/vacaciones/vacaciones-form/vacaciones-form.component';
import { AppRoutingModule } from './app-routing.module';
import { ListboxModule } from 'primeng/listbox';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TooltipModule } from 'primeng/tooltip';
import { VacacionesRhFormComponent } from './views/vacaciones-rh/vacaciones-rh-form/vacaciones-rh-form.component';
import { VacacionesRhComponent } from './views/vacaciones-rh/vacaciones-rh/vacaciones-rh.component';
import { DropdownModule } from 'primeng/dropdown';


@NgModule({
  declarations: [
    AppComponent,
    VacacionesComponent,
    VacacionesFormComponent,
    HomeComponent,
    ListaSolicitudesLiderFormComponent,
    ListaSolicitudesRhFormComponent,
    ListaVacacionesDashboardJefeComponent,
    ListaVacacionesDashboardRHComponent,
    VacacionesRhFormComponent,
    VacacionesRhComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RestClientModule,
    HttpClientModule,
    CommonsModule,
    InputTextModule,
    FormsModule,
    CommonsModule,
    CalendarModule,
    RadioButtonModule,
    ActionsBarModule,
    OperationsBarModule,
    ResultListModule,
    DetailTabModule,
    CardModule,
    ScrollPanelModule,
    TableModule,
    ToastModule,
    ButtonModule,
    ListboxModule,
    ProgressSpinnerModule,
    TooltipModule,
    DropdownModule
  ],
  providers: [MessageService, BaseMessages, SessionClientProvider,
    { provide: 'env', useValue: environment }]
  ,
  bootstrap: [AppComponent]
})
export class AppModule { }
