import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ARestClient } from '@axks/net';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class DashboardJefeRestcService {

  constructor(private httpClient: HttpClient) { }

  listaLider(idLider: number): Observable<any>{
    let path = environment.msDashboardjefe + "/" + idLider;
  
    const headers = new HttpHeaders()
        .set('Access-Control-Allow-Origin', "*")
        .set('Access-Control-Allow-Headers', "*");
        
    return this.httpClient.get(path, {headers});
  }
  /* constructor(httpClient: HttpClient) { 
    super(httpClient);
  }

  getBaseEndpoint(): string {
    return environment.msDashboardjefe+"/";
  }
  processBody(res: any) {
    let body = res ;
    return body || {};
  } */
}

