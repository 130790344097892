import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ListaSolicitudes } from 'src/app/api/lista-solicitudes/lista-solicitudes';
import { ListaSolicitudesRestcService } from 'src/app/client/lista-solicitudes/lista-solicitudes-restc.service';
import { MessageService } from 'primeng/api';
import { AutorizaVacaciones } from 'src/app/api/autoriza-vacaciones/autoriza-vacaciones';
import { AutorizaVacacionesRestcService } from 'src/app/client/autoriza-vacaciones/autoriza-vacaciones-restc.service';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { AutorizaPermisoVac } from '../../../api/autoriza-vacaciones/autoriza-vacaciones';

@Component({
  selector: 'axks-lista-solicitudes-lider-form',
  templateUrl: './lista-solicitudes-lider-form.component.html',
  styleUrls: ['./lista-solicitudes-lider-form.component.scss']
})
export class ListaSolicitudesLiderFormComponent extends BaseView implements OnInit {

  @Input() item: ListaSolicitudes;

  listaSol: ListaSolicitudes[] = [];

  autorizaVacaciones: AutorizaVacaciones = {}
  autorizaVAcacion: AutorizaVacaciones[] = [];
  AutorizaPermiso: AutorizaPermisoVac[] = [];


  constructor(
    protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private listaRestc: ListaSolicitudesRestcService,
    private autorizaLiderRestc: AutorizaVacacionesRestcService
  ) {
    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {
    if (!this.item) {
      this.item = {};
    }
    this.obtenerSolicitudes();
  }

  obtenerSolicitudes() {
    let profile = this.getProfile() as ProfileC;;
    this.item.idEmpleado = profile.idEmpleado;
    this.showProgress = true;

    this.listaRestc.listaLider(this.item.idEmpleado).subscribe(
      (data) => {
        this.listaSol = data as ListaSolicitudes[];
        this.showProgress = false;
        if (this.listaSol.length === 0) {
          this.messageService.add({ severity: 'info', summary: 'Sin solicitudes', detail: 'No hay ninguna solicitud de vacaciones por autorizar.' });
        }
      },
      (error) => {
        this.showProgress = false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No se pudo recuperar las solicitudes.' });
      }
    )
  }

  autorizar(valor: any) {

    let profile = this.getProfile() as ProfileC;;
    this.item.idLider = profile.idEmpleado;

    let aprovLider = {
      autorizacion: true,
      idLider: profile.idEmpleado,
      idVacaciones: valor.idVacaciones
    }

    this.showProgress = true;

    this.autorizaLiderRestc.autorizaVacacionesLider(aprovLider).subscribe(
      (data) => {
        this.autorizaVAcacion = data as AutorizaPermisoVac[];
        this.messageService.add({ severity: 'success', summary: 'Solicitud aceptada', detail: 'La solicitud se autorizó correctamente.' });
        this.showProgress = false;
        setTimeout(function () { location.reload(); }, 2000);
      },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No se pudo envíar la solicitud.' });
        this.showProgress = false;
      }
    );
  }

  rechazar(valor: any) {
    let profile = this.getProfile() as ProfileC;;
    this.item.idLider = profile.idEmpleado;

    let aprovLider = {
      autorizacion: false,
      idLider: profile.idEmpleado,
      idVacaciones: valor.idVacaciones
    }

    this.showProgress = true;

    this.autorizaLiderRestc.autorizaVacacionesLider(aprovLider).subscribe(
      (data) => {
        this.autorizaVAcacion = data as AutorizaPermisoVac[];

        this.messageService.add({ severity: 'success', summary: 'Solicitud rechazada', detail: 'La solicitud se rechazo correctamente.' });
        this.showProgress = false;
        setTimeout(function () { location.reload(); }, 2000);
      },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No se pudo envíar la solicitud.' });
        this.showProgress = false;
      }
    );
  }

}
